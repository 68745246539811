.menu{
  text-decoration: none;
  color:rgba(0, 0, 0, 0.54);
}

.active-link{
  text-decoration: none;

}

.active-link li{
  background-color:#eb6435 ;
  color: white;
}

.active-link li  .MuiTypography-body1{
  font-weight:600;
}

.active-link .css-cveggr-MuiListItemIcon-root{
  color: white;
}
