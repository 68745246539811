body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.dropzone {
  text-align: center;
  padding: 30px;
  border: 1px dashed #eb6435;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
  height: 100px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}

.MuiToolbar-regular{
display: flex;
align-items: baseline!important;
}
.required{
  color: red;
}
.MuiFormHelperText-root{
  color: red!important;
}
.loading-background{
  z-index: 10000!important;
}
thead,tfoot{
  background-color: gainsboro;
}
tfoot p{
  margin-top: 16px;
}